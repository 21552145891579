/* ===================================================================
 * 12. footer - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
footer {
	text-align: center;
	padding-bottom: 12rem;
	position: relative;
}

footer .social-wrap {
	background: #454545;
	margin-bottom: 4.8rem;
	margin-top: 4.8rem;
}

footer .footer-social-list {
	display: inline-block;
	list-style: none;
	margin: 0;
}

footer .footer-social-list a,
footer .footer-social-list a:visited {
	color: #FFFFFF;
}

footer .footer-social-list a:hover,
footer .footer-social-list a:focus {
	color: #919191;
}

footer .footer-social-list li {
	display: inline-block;
	padding: 0;
}

footer .footer-social-list li a {
	display: block;
	padding: 4.8rem 3rem 4.8rem;
}

footer .footer-social-list li i {
	display: block;
	font-size: 3.6rem;
}

footer .footer-social-list li span {
	display: block;
	margin-top: .9rem;
	font-size: 1.5rem;
}

footer .footer-bottom {
	position: relative;
}

footer .footer-bottom a,
footer .footer-bottom a:visited {
	color: #000000;
}

footer .footer-bottom a:hover,
footer .footer-bottom a:focus {
	color: #141414;
}

footer .footer-bottom .footer-logo {
	display: inline-block;
	width: 150px;
}

footer .copyright {
	display: block;
	position: relative;
	padding-top: 3.6rem;
	margin-top: 1.5rem;
}

footer .copyright::before {
	content: "";
	width: 120px;
	height: 3px;
	position: absolute;
	left: 50%;
	top: 0;
	margin-left: -60px;
	background: rgba(17, 17, 17, 0.3);
}

footer .copyright span {
	display: inline-block;
}

footer .copyright span::after {
	content: "";
	display: inline-block;
	padding: 0 1rem 0 1.2rem;
	color: rgba(17, 17, 17, 0.5);
}

footer .copyright span:last-child::after {
	display: none;
}

footer .back-to-top {
	font-family: 'PT Sans', sans-serif;
	font-family: 'Staatliches', cursive;
	font-size: 1.3rem;
	text-transform: uppercase;
	letter-spacing: .2rem;
	color: #FFFFFF;
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

footer .back-to-top a {
	display: block;
	background: #141414;
	padding: 1.2rem 3rem 1.5rem;
	color: #FFFFFF;
	cursor: pointer;
}

footer .back-to-top a:hover,
footer .back-to-top a:focus {
	background: #454545;
}

footer .back-to-top a i {
	margin-left: 15px;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * footer  
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	footer .footer-social-list li a {
		padding: 4.8rem 2rem 4.8rem;
	}

	footer .footer-social-list li i {
		font-size: 3.3rem;
	}

	footer .footer-social-list li span {
		font-size: 1.4rem;
	}

	footer .footer-bottom .footer-logo {
		width: 140px;
	}
}

@media only screen and (max-width:600px) {
	footer {
		padding-bottom: 10.2rem;
	}

	footer .footer-social-list li a {
		padding: 4.8rem 1.3rem 4.8rem;
	}

	footer .footer-social-list li i {
		font-size: 3rem;
	}

	footer .footer-social-list li span {
		font-size: 1.3rem;
	}

	footer .footer-bottom .footer-logo {
		width: 130px;
	}

	footer .copyright span {
		display: block;
	}

	footer .copyright span::after {
		display: none;
	}
}

@media only screen and (max-width:500px) {
	footer .footer-social-list li a {
		padding: 4.8rem 1.2rem 4.8rem;
	}

	footer .footer-social-list li span {
		display: none;
	}
}

@media only screen and (max-width:400px) {
	footer .footer-social-list li a {
		padding: 4.8rem 1.1rem 4.8rem;
	}

	footer .footer-bottom .footer-logo {
		width: 120px;
	}

	footer .back-to-top a {
		padding: 1.2rem 2.3rem 1.5rem;
	}
}