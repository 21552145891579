@import "~react-image-gallery/styles/css/image-gallery.css";
@import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
/* @import '@algolia/autocomplete-theme-classic/dist/theme.css'; */

* > body {
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PT Sans",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:visited,
a:link,
a:active {
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

.image-gallery-bullets {
  bottom: -2rem;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 4px solid #141414;
}

.image-gallery-bullets .image-gallery-bullet:hover {
  border: 1px solid #141414;
}

.image-gallery-icon:hover {
  color: #141414;
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

.customModal {
  max-width: 935px;
  width: 100%;
}

.react-responsive-modal-modal {
  padding: 0px !important;
}

.rce-sbar.light {
  background-color: #F0F0F0;
}

.rce-sbar-item {
  width: 100%;
}

.cs-message--incoming .cs-message__content {
  background-color: #F0F0F0;
  color: "black";
}

.cs-message.cs-message--incoming.cs-message--first .cs-message__content {
  background-color: #F0F0F0;
}

.cs-message--outgoing .cs-message__content {
  background-color: #141414;
  color: white;
}

.cs-message.cs-message--outgoing.cs-message--first .cs-message__content {
  background-color: #141414;
}

.cs-conversation-header {
  background-color: white;
}

.cs-conversation-header__content .cs-conversation-header__user-name {
  background-color: white;
}

.cs-conversation-header__content .cs-conversation-header__info {
  background-color: white;
}

.cs-button--arrow {
  color: #141414;
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor {
  background-color: white;
}

.cs-chat-container .cs-message-input {
  background-color: #F0F0F0;
}

.cs-button--attachment,
.cs-button--send,
.cs-search__search-icon {
  color: #141414;
}

.cs-sidebar--left {
  background-color: #F0F0F0;
}

.cs-search,
.cs-search__input {
  background-color: white;
}

.cs-message-separator::before,
.cs-message-separator::after {
  background-color: #141414;
}

.cs-typing-indicator__dot {
  background-color: #141414;
}

.cs-typing-indicator__text {
  color: #141414;
}

.cs-conversation:hover,
.cs-conversation:active,
.cs-conversation:target,
.cs-conversation:focus,
.cs-conversation:focus-visible {
  background-color: #aaaaaa;
}

.cs-conversation.cs-conversation--active {
  background-color: #d9d9d9;
}

.cs-conversation.cs-conversation--active .cs-conversation__name {
  color: #272727;
}

.cs-conversation.cs-conversation--active .cs-conversation__info {
  color: #6f6f6f;
}

.cs-conversation__name {
  color: #272727;
}

.cs-conversation__info {
  color: #c9c9c9;
}

.cs-conversation-header__content .cs-conversation-header__user-name {
  color: #141414;
}

.cs-conversation__info-content {
  box-sizing: border-box;
  display: inline;
  font-weight: inherit !important;
}

.aa-panel {
  z-index: 2000 !important;
}

:root {
  --aa-icon-size: 36px;
  --aa-icon-color-rgb: 20, 20, 20;
  --aa-panel-border-color-rgb: 20, 20, 20;
  /* --aa-input-border-color-rgb: 20, 20, 20; */
  --aa-input-border-color-rgb: 255, 255, 255;
  --aa-input-background-color-rgb: 248, 248, 248;
  --aa-selected-color-rgb: 20, 20, 20;
  --aa-primary-color-rgb: 20, 20, 20;
}

.aa-Autocomplete:focus {
  border-color: #141414 !important;
}

.aa-DetachedSearchButtonIcon,
.aa-SubmitIcon {
  border-color: #141414 !important;
}

.MuiSvgIcon-colorPrimary {
  color: #141414 !important;
}

.image-gallery-icon {
  filter: none;
  color: #707070;
}

.image-gallery-bullets .image-gallery-bullet {
  box-shadow: none;
  border: none;
  background-color: #c9c9c9;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background-color: #141414;
  border: none;
}

.aa-DetachedSearchButton {
  border: 0;
}

.ais-SearchBox-input {
  border: 1px solid #c4c4c4;
  color: #6f6f6f;
  caret-color: #6f6f6f;
  box-shadow: none;
}

.ais-SearchBox-input:focus {
  border: 1px solid #6f6f6f;
  color: #6f6f6f;
  caret-color: #6f6f6f;
  box-shadow: none;
}

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RatingMenu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
  border: 0;
  color: #fff;
  background: #141414;
}

.ais-GeoSearch-input,
.ais-RefinementList-checkbox {
  box-shadow: none;
  color: #141414;
}

.ais-GeoSearch-input:focus,
.ais-RefinementList-checkbox:focus {
  box-shadow: none;
  color: #141414;
}

.ais-ClearRefinements-button:disabled[disabled],
.ais-GeoSearch-redo--disabled,
.ais-GeoSearch-reset--disabled,
.ais-InfiniteHits-loadMore:disabled[disabled],
.ais-InfiniteHits-loadPrevious:disabled[disabled],
.ais-Pagination-item--disabled .ais-Pagination-link,
.ais-VoiceSearch-button:disabled {
  background-image: none;
  border-color: #6f6f6f;
  color: #6f6f6f;
}

.aa-Detached {
  overflow: auto;
}

.hide-count .ais-HierarchicalMenu-count,
.hide-count .ais-RefinementList-count {
  display: none;
}

.cs-button--attachment:after {
  content: "$";
  text-style: bold;
  font-size: 1.1em;
  line-height: 1em;
}

.cs-button--attachment svg {
  display: none;
}
