.proposalButton {
  width: 100%;
  background-color: #141414 !important;
  color: #fff !important;
}

.proposalButtonSecondary {
  width: 100%;
  background-color: #454545 !important;
  color: #fff !important;
}

.titleProposal {
  h2 {
    color: #169999;
    border-bottom: 1px solid #c9c9c9;
  }
}
