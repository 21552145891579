.fchat{
   
}

.closeDialogButton{
    position: absolute !important;
    top: -50px;
    z-index: 999999999999999;
    right: -40px;
}

.titleProposal{
    h2{
        color: #169999;
        border-bottom: 1px solid #C9C9C9;
    }
}

.proposalButton {
    width: 100%;
    background-color: #141414 !important;
    color: #fff !important;
}

.infosSummary{
    margin: 0px !important;
}